// If you want to override variables do it here
@import "variables";

// Import CoreUI styles
@import "~@coreui/coreui/scss/coreui.scss";

// Import Font Awesome Icons Set
@import '~font-awesome/css/font-awesome.min.css';

// Some temp fixes
@import "fixes";

// If you want to add something do it here
@import "custom";

@import "helpers";

@import "checkbox-type";
@import "react-select-custom";

