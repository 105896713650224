.mt-30{
    margin-top: 30px;
}

.mt-25{
    margin-top: 25px;
}

.mt-20{
    margin-top: 20px;
}

.mt-15{
    margin-top: 15px;
}

.mb-30{
    margin-bottom: 30px;
}

.mb-25{
    margin-bottom: 25px;
}

.mb-20{
    margin-bottom: 20px;
}

.mb-15{
    margin-bottom: 15px;
}

.dont-break-out {

    /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;
  
    -ms-word-break: break-all;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;
  
    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
  
}

.font-weight-bold{
    font-weight: bold;
}

.square-45px{
    width:  40px;
    height: 40px;
}