// todo: disabled button styles
button {
  &:disabled {
    cursor: default;
  }
  &.disabled {
    cursor: default;
  }
}

// todo: brand button icon margin
.btn-brand:not(:only-child) {
  .c-icon {
    margin-top: 0 !important;
  }
}



// datepicker library display block
.react-datepicker-wrapper{
	display: block !important;
}

.react-datepicker__input-container{
	display: block !important;
}

.react-datepicker-popper2 {
  z-index: 99999 !important;
}

.react-datepicker-popper {
  z-index: 5 !important;
}