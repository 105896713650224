// Here you can add other styles

// inputs color 
input[type="text"], input[type="number"], input[type="email"],
input[type="password"],textarea, select{
    color: #333333;
    &:focus, &:active, &:hover{
        color: #111111;
    }
}

textarea, select{
    color: #333333 !important;
}


// library time picker
.react-datepicker-popper2 {
    z-index: 99999 !important;
}

.react-datepicker-popper {
    z-index: 5 !important;
}

.react-datepicker-wrapper{
	display: block !important;
}

.react-datepicker__input-container{
	display: block !important;
}

.rc-time-picker-input{
    border: none !important;
    padding: 0px !important;
    height: auto !important;
}